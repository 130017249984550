import React, {Component} from 'react'

export default class Footer extends Component {

    render() {

        return(
            <div>
                <div className="footer">
                    <span>Built with React and Python | <a href="https://iexcloud.io" target="_blank" rel="noopener noreferrer">Data provided by IEX Cloud</a></span>
                </div>
            </div>
        )

    }

}