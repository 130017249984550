import React, {Component} from 'react';

export default class About extends Component {

    render() {

        return(
            <div className="main-section">
                <div className="sub-section">
                    <div className="text-area">
                        <h3>About TickerDash</h3>
                        <p>Existing stock and brokerage apps are way too complicated.  Retail traders need an easier way to
                        see what's going on with their portfolio.  TickerDash uses SMS/MMS to help fill the gap.</p>
                    </div>
                </div>
            </div>
        )

    }

}