import React, {Component} from 'react'

export default class Navigation extends Component {

    checkForCookie = () => {
    
        if (sessionStorage.getItem('accessToken') !== '' && sessionStorage.getItem('accessToken') !== null) {
            return true;
        } else {
            return false;
        }
    }

    render() {

        return(
            <nav>
            { this.checkForCookie() && 
                
                <>
                <a className="nav-links" href="/" onClick = {(e) => this.props.navLinkHandler(e, "logout")}>Log Out</a>
                <a className="nav-links" href="/" onClick={(e) => this.props.navLinkHandler(e, "tickers")}>Your Tickers</a>
                <a className="nav-links" href="/" onClick={(e) => this.props.navLinkHandler(e, "about")}>About</a>
                <a className="nav-links" href="/" onClick = {(e) => this.props.navLinkHandler(e, "contact")}>Feedback</a>
                <a className="nav-links" href="https://twitter.com/TickerSocial" target="_blank" rel="noopener noreferrer">Twitter</a>
                </>
            }
            { !this.checkForCookie() && 
                <>
                <a className="nav-links" href="/" onClick = {(e) => this.props.navLinkHandler(e, "login")}>Get Started</a>
                <a className="nav-links" href="/" onClick={(e) => this.props.navLinkHandler(e, "about")}>About</a>
                <a className="nav-links" href="/" onClick = {(e) => this.props.navLinkHandler(e, "contact")}>Feedback</a>
                <a className="nav-links" href="https://twitter.com/TickerSocial" target="_blank" rel="noopener noreferrer">Twitter</a>
                </>
            }
            </nav>
        )

    }

}