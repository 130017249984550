import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Login extends Component {

    static propTypes = {
        formSubmitHandler: PropTypes.func
    }

    render() {

        return(
            <div className="main-section">
                <div className="sub-section">
                    <div className="text-area">
                        <h3>Login with SMS</h3>
                        <p>(standard text message rates apply)</p>
                        <form id="login_form" className="login-form" onSubmit={(e) => this.props.loginFormSubmitHandler(e)}>
                            <label className='login-form'>
                                <div id="login-label-text" className='label-text'>Phone</div>
                                <input id="login-phone-field" className="phone-field" type="text" name="phone"/>
                                <input id="login-submit" className="submit-button" type="submit" value="Submit"/>
                            </label>
                        </form>
                        {this.props.showError &&
                            <div className='error'>Invalid</div>
                        }

                    <div className="features">
                        <h3>Features</h3>
                        <ul>
                            <li className='feature_item'>SMS Messages for price, 52 week high/low and projected dividend pay date</li>
                            <li className='feature_item'>Messages send shortly after Market Open, Lunch and Market Close</li>
                            <li className='feature_item'>Respond to TickerDash bot for ticker price and news headlines (any ticker/company).</li>
                            <li className='feature_item'>Painless sign-up.  Don't sign your life away.</li>
                            <li className='feature_item'>No need to login every day.  Set it and forget it.</li>
                            <li className='feature_item'>Free for 5 tickers.  Premium features coming soon!</li>
                        </ul>
                    </div>



                    </div>
                </div>
            </div>
        )

    }

}