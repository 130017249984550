import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Tickers extends Component {

    static propTypes = {
        skills: PropTypes.array
    }

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {

        return(
            <div className="main-section">
                <div className="sub-section">
                    <div className="text-area">
                        <h3>Tickers</h3>

                        <p>Add up to 5 tickers to receive daily stock alerts (standard text message rates apply).</p>
                        <p>Alerts will arrive shortly after market Open, Lunch and Close for NYSE and Nasdaq.</p>

                        {this.props.showError &&
                            <div className='error'>Invalid</div>
                        }
                        {this.props.showNoContent &&
                            <div className='error'>Nothing Found</div>
                        }
                        {this.props.ticker_data.length < 5 &&
                            <>
                            <form id="company_form" className="contact-form" onSubmit={(e) => this.props.companyFormSubmitHandler(e)}>
                                <label className='ticker-form'>
                                    <div className='label-text'>Company</div>
                                    <input type="text" name="company" className='company-field'/>
                                    <input className="submit-button" type="submit" value="Search"/>
                                </label>
                            </form>
                            <form id="ticker_form" className="contact-form" onSubmit={(e) => this.props.tickerFormSubmitHandler(e)}>
                                <label className='ticker-form'>
                                    <div className='label-text'>Ticker</div>
                                    <input type="text" name="ticker" className='ticker-field' value={this.props.company} onChange={(e) => this.props.tickerOnChange(e.target.value)}/>
                                    <input className="submit-button" type="submit" value="Add Ticker"/>
                                </label>
                            </form>
                            </>
                        }
                        { this.props.ticker_data &&
                        
                            <>
                            <ul className='ticker_list'>
                                { this.props.ticker_data &&
                                    this.props.ticker_data.map(ticker => 
                                        <div key={ticker.id}><div className='ticker_div'><li key={ticker.id} className='ticker'>{ticker.ticker}</li></div>
                                        <button onClick={(e) => this.props.deleteTickerFormSubmitHandler(e, ticker.id)} className="delete-button" type="click" value="Remove Ticker">Remove Ticker</button></div>
                                    )
                                }
                            </ul>

                            <label className='frequency-form'>
                                <div className='label-text'><b>Alert Frequency:</b></div>
                                <form>
                                    <label>
                                        <input key={this.props.frequency} type="radio" name='frequency' value='morning' checked={this.props.frequency === 'morning'} onChange={(e) => this.props.frequencyOnChange(e, e.target.value)}/>
                                        Morning
                                    </label>
                                    <label>
                                        <input key={this.props.frequency} type="radio" name='frequency' value='lunch' checked={this.props.frequency === 'lunch'} onChange={(e) => this.props.frequencyOnChange(e, e.target.value)}/>
                                        Lunch
                                    </label>
                                    <label>
                                        <input key={this.props.frequency} type="radio" name='frequency' value='close' checked={this.props.frequency === 'close'} onChange={(e) => this.props.frequencyOnChange(e, e.target.value)}/>
                                        Close
                                    </label>
                                    <label>
                                        <input key={this.props.frequency} type="radio" name='frequency' value='all' checked={this.props.frequency === 'all'} onChange={(e) => this.props.frequencyOnChange(e, e.target.value)}/>
                                        All
                                    </label>
                                </form>
                            </label>
                            </>
                        }
                    </div>
                </div>
            </div>
        )

    }

}