import React, {Component} from 'react';
import Navigation from './components/navigation';
import Footer from './components/footer';
import About from './components/about';
import Login from './components/login';
import VerifyLogin from './components/verify_login';
import Tickers from './components/tickers';
import Contact from './components/contact';
import Dash from './components/dash';
import axios from 'axios';
import './styles/App.scss';
import ReactGA from'react-ga';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';

export default class App extends Component {

  formUrl = process.env.REACT_APP_FORM_URL;
  loginFormUrl = process.env.REACT_APP_LOGIN_FORM_URL;
  tickerFormUrl = process.env.REACT_APP_TICKER_FORM_URL;
  deleteTickerFormUrl = process.env.REACT_APP_DELETE_TICKER_URL;
  verifyLoginFormUrl = process.env.REACT_APP_VERIFY_LOGIN_FORM_URL;
  loadTickersUrl = process.env.REACT_APP_LOAD_TICKERS_URL;
  companyFormUrl = process.env.REACT_APP_COMPANY_FORM_URL;
  setFrequencyFormUrl = process.env.REACT_APP_SET_FREQUENCY_URL;
  priceUrl = process.env.REACT_APP_PRICE_URL

  constructor(props) {
    super(props);

    this.state = {
      showAbout: false,
      showLogin: true,
      showVerifyLogin: false,
      showTickers: false,
      showContact: false,
      showFooter: true,
      showNav: true,
      showError: false,
      showNoContent: false,
      data: {

      },
      ticker_data: [],
      company: '',
      frequency: '',
      form: {

      }
    }
  }

  componentDidMount() {
    this.initializeReactGA();

    let cookiePresent = this.checkForCookie();

    if (cookiePresent) {
      this.pullFromApi(this.loadTickersUrl);
      this.pullFrequencyFromApi(this.setFrequencyFormUrl)
    }

    console.log(window.location.pathname)
    if (window.location.pathname.includes('dash')) {
      this.hideAllSections()

      this.setState({
        showNav: false,
        showFooter: false,
      })
    }

    let ticker = window.location.pathname.split('/dash/')[1]
    if (ticker !== undefined) {
      this.pullPriceDataFromApi(ticker)
    }
  }

  initializeReactGA = () => {
    ReactGA.initialize('UA-166896474-1');
    ReactGA.pageview('/homepage');
  }
  
  checkForCookie = () => {

    if (sessionStorage.getItem('accessToken') !== '' && sessionStorage.getItem('accessToken') !== null) {
      this.setState({
        showAbout: false,
        showLogin: false,
        showTickers: true,
        showContact: false
      })

      return true;
    } else {
      this.setState({
        showAbout: false,
        showLogin: true,
        showTickers: false,
        showContact: false
      })

      return false;
    }
  }

  pullFromApi = async (dataUrl) => {

    try {
        let token = sessionStorage.getItem('accessToken')
        const response = await axios.post(dataUrl, {}, { headers:
            {Authorization: "Bearer " + token}
        });

        const ticker_data = response.data;
        this.setState({ ticker_data: ticker_data })
        
    } catch (error) {

      if (error.response !== undefined) {
        console.log("error", error.response.status);

        if (error.response.status === 440) {
          sessionStorage.removeItem('accessToken')
          window.location.reload()
        }
      }

    }

  }

  pullFrequencyFromApi = async (dataUrl) => {

    try {
        let token = sessionStorage.getItem('accessToken')
        const response = await axios.post(dataUrl, {}, { headers:
            {Authorization: "Bearer " + token}
        });

        const frequency = response.data;
        this.setState({ frequency: frequency })
        
    } catch (error) {

      if (error.response !== undefined) {
        console.log("error", error.response.status);

        if (error.response.status === 440) {
          sessionStorage.removeItem('accessToken')
          window.location.reload()
        }
      }

    }

  }

  pullPriceDataFromApi = async (user) => {

    try {
        const response = await axios.post(this.priceUrl, {
          data: {user: user}
        });

        const price = response.data;

        console.log(price)
        this.setState({ price: price })
        
    } catch (error) {

      if (error.response !== undefined) {
        console.log("error", error.response.status);
      }

    }

  }

  loadHomePage = () => {

    this.checkForCookie();
    
  }

  navLinkHandler = (e, page) => {
    e.preventDefault()

    if (page === 'about') {
      ReactGA.pageview('/about')
      this.hideAllSections()
      this.setState({
        showAbout: !this.state.showAbout ? true : true
      })
    }

    if (page === 'login') {
      ReactGA.pageview('/login')
      this.hideAllSections()
      this.setState({
        showLogin: !this.state.showLogin ? true : true,
      })
    }

    if (page === 'logout') {
      ReactGA.pageview('/logout')
      sessionStorage.removeItem('accessToken')
      this.hideAllSections()
      this.setState({
        showLogin: !this.state.showLogin ? true : true,
        showTickers: !this.state.showTickers ? false : false
      })
    }

    if (page === 'verify_login') {
      ReactGA.pageview('/verify_login')
      this.hideAllSections()
      this.setState({
        showVerifyLogin: !this.state.showVerifyLogin ? true : true,
      })
    }

    if (page === 'tickers') {
      ReactGA.pageview('/tickers')
      this.hideAllSections()
      this.setState({
        showTickers: !this.state.showTickers ? true : true
      })
    }

    if (page === 'contact') {
      ReactGA.pageview('/contact')
      this.hideAllSections()
      this.setState({
        showContact: !this.state.showContact ? true : true
      })
    }
  }

  formSubmitHandler = (e) => {

    e.preventDefault()

    if (e.target.name.value === '' && e.target.phone.value === ''  && e.target.email.value === '' 
      && e.target.comments.value === '' ) {
      console.log('empty form!');
      ReactGA.event({
        category: 'User',
        action: 'Empty Form'
      });
    } else {
      
      this.setState({
        form: {
          "name" : e.target.name.value,
          "phone": e.target.phone.value,
          "email": e.target.email.value,
          "comments": e.target.comments.value
        }
        
      }, async () => {
  
        try {

          ReactGA.event({
            category: 'User',
            action: 'Submit Form'
          });
          const response = await axios.post(this.formUrl, {data: this.state.form});
          console.log(response.data);
          document.getElementById("contact_form").reset();
    
        } catch (error) {
          console.log("error", error);
        }

      })
    }
  }

  loginFormSubmitHandler = (e) => {

    e.preventDefault()

    if (e.target.phone.value === '') {
      console.log('empty form!');
      ReactGA.event({
        category: 'User',
        action: 'Empty Form'
      });
    } else {
      
      this.setState({
        form: {
          "phone": e.target.phone.value
        }
        
      }, async () => {
  
        try {

          ReactGA.event({
            category: 'User',
            action: 'Login Form'
          });

          const response = await axios.post(this.loginFormUrl, {data: this.state.form});

          document.getElementById("login_form").reset();

          this.setState({
            showLogin: !this.state.showLogin ? false : false,
            showVerifyLogin: !this.state.showVerifyLogin ? true : true,
            showError: false
          })
    
        } catch (error) {
          console.log("error", error);

          this.setState({
            showError: true
          })

          this.messageTimer()
        }

      })
    }
  }

  verifyLoginFormSubmitHandler = (e) => {

    e.preventDefault()

    if (e.target.code.value === '') {
      console.log('empty form!');
      ReactGA.event({
        category: 'User',
        action: 'Empty Form'
      });
    } else {
      
      this.setState({
        form: {
          "code": e.target.code.value
        }
        
      }, async () => {
  
        try {

          ReactGA.event({
            category: 'User',
            action: 'Verify Login Form'
          });

          const response = await axios.post(this.verifyLoginFormUrl, {data: this.state.form});
          document.getElementById("verify_login_form").reset();

          sessionStorage.accessToken = response.data.access_token;

          this.setState({
            showVerifyLogin: !this.state.showVerifyLogin ? false : false,
            showTickers: !this.state.showTickers ? true : true,
          })

          this.pullFromApi(this.loadTickersUrl)
          this.pullFrequencyFromApi(this.setFrequencyFormUrl)

          this.setState({
            showError: false
          })
    
        } catch (error) {

          this.setState({
            showError: true
          })

          this.messageTimer()

          console.log("error", error);
        }

      })
    }
  }

  tickerFormSubmitHandler = (e) => {

    e.preventDefault()

    if (e.target.ticker.value === '') {
      console.log('empty form!');
      ReactGA.event({
        category: 'User',
        action: 'Empty Form'
      });
    } else {
      
      this.setState({
        form: {
          "ticker": e.target.ticker.value
        }
        
      }, async () => {
  
        try {

          ReactGA.event({
            category: 'User',
            action: 'Ticker Form'
          });

          let token = sessionStorage.getItem('accessToken')

          const response = await axios.post(this.tickerFormUrl, {data: this.state.form}, { headers:
            {Authorization: "Bearer " + token}
          });

          this.setState({ company: '' })

          this.pullFromApi(this.loadTickersUrl);

          this.setState({
            showError: false
          })
    
        } catch (error) {

          this.setState({
            showError: true
          })

          if (error.response !== undefined) {
    
            if (error.response.status === 440) {
              sessionStorage.removeItem('accessToken')
              window.location.reload()
            }
          }

          this.messageTimer()
        }

      })
    }
  }

  companyFormSubmitHandler = (e) => {

    e.preventDefault()

    if (e.target.company.value === '') {
      console.log('empty form!');
      ReactGA.event({
        category: 'User',
        action: 'Empty Form'
      });
    } else {
      
      this.setState({
        form: {
          "company": e.target.company.value
        }
        
      }, async () => {
  
        try {

          ReactGA.event({
            category: 'User',
            action: 'Company Form'
          });

          let token = sessionStorage.getItem('accessToken')

          const response = await axios.post(this.companyFormUrl, {data: this.state.form}, { headers:
            {Authorization: "Bearer " + token}
          });

          document.getElementById("company_form").reset();

          const company = response.data;
          this.setState({ company: company })

          this.pullFromApi(this.loadTickersUrl);

          this.setState({
            showError: false
          })

          if (response.status === 204) {

            this.setState({
              showNoContent: true
            })

            this.messageTimer()

          } else {
            this.setState({
              showNoContent: false
            })
          }
    
        } catch (error) {

          this.setState({
            showError: true
          })

          if (error.response !== undefined) {
            console.log("error", error.response.status);
    
            if (error.response.status === 440) {
              sessionStorage.removeItem('accessToken')
              window.location.reload()
            }

          }

          this.messageTimer()
        }

      })
    }
  }

  deleteTickerFormSubmitHandler = (e, ticker_id) => {

    e.preventDefault()

    if (ticker_id === '') {
      console.log('empty form!');
      ReactGA.event({
        category: 'User',
        action: 'Empty Form'
      });
    } else {
      
      this.setState({
        form: {
          "ticker_id": ticker_id
        }
        
      }, async () => {
  
        try {

          ReactGA.event({
            category: 'User',
            action: 'Delete Ticker'
          });

          let token = sessionStorage.getItem('accessToken')
          const response = await axios.post(this.deleteTickerFormUrl, {data: this.state.form}, { headers:
            {Authorization: "Bearer " + token}
          });

          this.pullFromApi(this.loadTickersUrl);
    
        } catch (error) {
          console.log("error", error);
        }

      })
    }
  }

  setFrequencyFormSubmitHandler = (e) => {

    e.preventDefault()

    if (e.target.value === '') {
      console.log('empty form!');
      ReactGA.event({
        category: 'User',
        action: 'Empty Form'
      });
    } else {
      
      this.setState({
        form: {
          "frequency": e.target.value
        }
        
      }, async () => {
  
        try {

          ReactGA.event({
            category: 'User',
            action: 'Set Frequency'
          });

          let token = sessionStorage.getItem('accessToken')
          const response = await axios.post(this.setFrequencyFormUrl, {data: this.state.form}, { headers:
            {Authorization: "Bearer " + token}
          });
          console.log(response.data);

          this.pullFromApi(this.loadTickersUrl);
    
        } catch (error) {
          console.log("error", error);
        }

      })
    }
  }

  hideAllSections = () => {

    this.setState({
      showAbout: false,
      showLogin: false,
      showVerifyLogin: false,
      showTickers: false,
      showContact: false,
    })

  }

  tickerOnChange = (value) => {
    this.setState({ company: value })
  }

  frequencyOnChange = (e, value) => {
    this.setState({ frequency: value })
    this.setFrequencyFormSubmitHandler(e)
  }

  messageTimer = () => {
    setTimeout(() => {
      this.setState({
        showNoContent: false,
        showError: false
      })
    }, 400);
  }

  render() {
    return (
      <div className="App">
        
        <div onClick={() => this.loadHomePage()}>
            <header className="App-header">
              <div>TickerDash</div>
              <div className='tagline'>Text Message Alerts for Stocks</div>
            </header>
        </div>

        { this.state.showNav &&
          <Navigation
            navLinkHandler = {this.navLinkHandler}
            navClasses = {this.state.navClasses}
          />
        }

        { this.state.showAbout && 
          <About

          />
        }

        { this.state.showLogin && 
          <Login
            loginFormSubmitHandler = {this.loginFormSubmitHandler}
            showError = {this.state.showError}
          />
        }

        { this.state.showVerifyLogin && 
          <VerifyLogin
            verifyLoginFormSubmitHandler = {this.verifyLoginFormSubmitHandler}
            showError = {this.state.showError}
          />
        }

        { this.state.showTickers && 
          <Tickers
           tickerFormSubmitHandler = {this.tickerFormSubmitHandler}
           companyFormSubmitHandler = {this.companyFormSubmitHandler}
           deleteTickerFormSubmitHandler = {this.deleteTickerFormSubmitHandler}
           ticker_data = {this.state.ticker_data}
           company = {this.state.company}
           frequency = {this.state.frequency}
           tickerOnChange = {this.tickerOnChange}
           frequencyOnChange = {this.frequencyOnChange}
           showError = {this.state.showError}
           showNoContent = {this.state.showNoContent}
          />
        }

        { this.state.showContact && 
          <Contact
            formSubmitHandler = {this.formSubmitHandler}
          />
        }

        { this.state.showFooter &&
          <Footer/>
        }

        <Router>
          <Switch>
            <Route path="/dash/:ticker" component={(props) => <Dash pullPriceDataFromApi = {this.pullPriceDataFromApi} price = {this.state.price} {...props} />}>
            </Route>
          </Switch>
        </Router>
      </div>
    )
  }
}