import React, {Component} from 'react'

export default class Dash extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

        
    }

    componentDidMount() {

        let user = this.props.location.pathname.split('/dash/')[1]
        this.setState({
            user: user,
        })
    }

    render() {

        return(
            <div className="main-section">
                <div className="sub-section">
                    <div className="text-area">
                        {this.state.user &&
                            <>
                                <ul className='price_list'>
                                    { this.props.price &&
                                        this.props.price.map(price => 
                                            <li key={price.price} className='tickerbox'>
                                                    <ul>
                                                        <li key={price.price} className='prices'>
                                                            <div className='priceleft'><b>{price.ticker}</b></div> <div className='priceright'>${price.price}</div>
                                                        </li>
                                                        <li key={price.low} className='prices-low'>
                                                            <div className='priceleft'>52-Week Low:</div> <div className='priceright'>${price.low}</div>
                                                        </li>
                                                        <li key={price.high} className='prices-high'>
                                                            <div className='priceleft'>52-Week High:</div> <div className='priceright'>${price.high}</div>
                                                        </li>
                                                    </ul>
                                            </li>

                                        )
                                    }
                                </ul>
                            </>
                        }
                    </div>
                </div>
            </div>
        )

    }

}