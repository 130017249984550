import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class VerifyLogin extends Component {

    static propTypes = {
        formSubmitHandler: PropTypes.func
    }

    render() {

        return(
            <div className="main-section">
                <div className="sub-section">
                    <div className="text-area">
                        <h3>Verify Code</h3>
                        <form id="verify_login_form" className="login-form" onSubmit={(e) => this.props.verifyLoginFormSubmitHandler(e)}>
                            <label className='verify-form'>
                                <div className="label-text">Code</div>
                                <input className="code-field" type="text" name="code"/>
                                <input className="submit-button" type="submit" value="Submit"/>
                            </label>
                        </form>
                        {this.props.showError &&
                            <div className='error'>Invalid</div>
                        }
                    </div>
                </div>
            </div>
        )

    }

}